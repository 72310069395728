<template>
  <div
    v-if="data && charts"
    class="measure-list-item for-pdf"
  >
    <div
      class="measure-info"
    >
      <div class="measure-info-header">
        <div class="measure-info-abbr abbreviation">
          {{ data.abbreviation }} on {{ titleDateFromContext }}
        </div>
      </div>
      <div class="measure-info-details">
        <measure-list-item-detail-info
          :data="data"
          :result-suggestion="resultSuggestion"
          :assignment="assignment"
          :date="date"
        />
      </div>
    </div>

    <div
      v-if="hasSubscores && showChartSkippedMessage"
      class="session-view-chart"
    >
      <div
        v-if="showChartSkippedMessage"
        class="missing-answers-div"
      >
        <p>
          <strong>{{ $t('measuresCantBeScored') }}</strong>
          {{ $t('questionRequiredToScore') }}
        </p>
      </div>
    </div>

    <div
      v-else-if="!data.has_graph && !data.is_bundle_measure"
      class="measure-not-score"
    >
      <b>{{ $t('scoringWithNoGraph.title') }}</b> {{ $t('scoringWithNoGraph.description') }}
    </div>

    <div
      v-else-if="data.completedDataWithScores === 0"
      class="measure-not-score"
    >
      <b>{{ $t('scoringWithGraphButNoData.title') }}</b> {{ $t('scoringWithGraphButNoData.description') }}
    </div>

    <div
      v-else
      class="session-view-chart"
    >
      <div
        v-for="(item, index) in data.chart"
        :key="index"
      >
        <bar-chart
          v-if="data.completedDataWithScores"
          :data="item.session_chart[0]"
          :num-decimal-digits="item.session_chart[0].num_decimal_digits"
          :colors="colors"
        />
        <div
          v-if="item.session_chart[0].chart_tooltip_text !== null"
          class="measure-chart-footer"
        >
          <h4>
            {{ $t('clinicianPatient.seeGraphAbove', { abbreviation: data.abbreviation }) }}
          </h4>
        </div>
      </div>
    </div>
    <measure-questions
      v-if="questionArray"
      ref="measureQuestionsElement"
      :more-info="data.more_info"
      :data="data"
      :question-array="questionArray"
      :audition="false"
    />
    <measure-questions
      v-else
      ref="measureQuestionsElement"
      :more-info="data.more_info"
      :data="data"
      :audition="false"
    />
  </div>
</template>
<script>
import MeasureQuestions from '@/components/clinician/patients/measures/MeasureQuestions'
import MeasureListItemDetailInfo from '@/components/clinician/patients/measures/MeasureListItemDetailInfo'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { PatientHelper } from '@/mixins/PatientHelper'
import { Helpers } from '@/mixins/Helpers'
import BarChart from '@/components/clinician/patients/measures/charts/BarChart'

export default {
  name: 'SessionDetailPDF',
  components: {
    MeasureQuestions,
    BarChart,
    MeasureListItemDetailInfo
  },
  mixins: [Helpers, PatientHelper, DateTimeHelper],
  props: ['data', 'charts', 'measureIndex', 'assignment', 'session', 'questionArray', 'singleMeasureCompletedSessions', 'measureLoading', 'missingAnswers'],
  data () {
    return {
      colors: ['#7cb5ec', '#90ed7d', '#f7a35c', '#8085e9', '#e4d354', '#2f7ed8', '#c42525', '#a6c96a', '#E6B333', '#3366E6', '#B34D4D', '#80B300', '#E6B3B3', '#66991A', '#FF99E6', '#CCFF1A', '#FF6633', '#00B3E6'],
      chartSelected: null
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    showChartSkippedMessage () {
      let show = false
      if (this.chartSelected && this.data) {
        if ((this.chartSelected.unanswered_questions && this.data.completedDataWithScores < 1) || (this.missingAnswers > 0)) {
          show = true
        }
      }
      return show
    },
    // messageIsSkippedQuestionMessage () {
    //   return this.justOneMeasureComplete ? this.data.sessions_completed[0].unanswered_questions : false
    // },
    justOneMeasureComplete () {
      return this.data.sessions_completed && this.data.sessions_completed.length === 1
    },
    // eslint-disable-next-line vue/return-in-computed-property
    resultSuggestion () {
      if (this.chartSelected) {
        if (this.showChartSkippedMessage) {
          return null
        }
        if (this.chartSelected.result_suggestions) {
          return this.chartSelected.result_suggestions
        }
        if (this.chartSelected.sessions_progress_results) {
          const found = this.chartSelected.sessions_progress_results.find(r => r.result_suggestions && r.result_suggestions.length)
          return found ? found.result_suggestions : null
        }
        return null
      }
    },
    titleDate () {
      return (this.measureLoading === false && this.singleMeasureCompletedSessions.length > 0)
        ? this.$toLocal(this.singleMeasureCompletedSessions[0].completion_date, 'MMM DD, YYYY')
        : null
    },
    titleDateRedox () {
      let date = null
      if (this.data.sessions_completed.length) {
        const session = this.data.sessions_completed.filter(session => session.id === this.data.id)
        date = session.length ? session[0].measure_date_completed : null
      }
      return date ? this.$toLocal(date, 'MMM DD, YYYY') : null
    },
    titleDateFromContext () {
      return this.session ? this.titleDateRedox : this.titleDate
    },
    date () {
      const dueDate = this.getMeasureDueDate()
      return dueDate !== false ? dueDate : this.$t('none')
    }
  },
  created () {
    if (this.data && this.data.chart.length > 0) {
      try {
        this.chartSelected = this.data.chart[0].session_chart[0]
      } catch (error) {}
    }
    document.body.classList.add('session-detail-body')
  },
  methods: {
    getMeasureDueDate () {
      if (this.data) {
        return this.data.next_schedules && this.data.next_schedules.length ? this.data.next_schedules[0].due_date : false
      }
    },
    hasSubscores () {
      return (this.data.chart.length > 1)
    }
  }
}
</script>
<style lang="scss" scoped>
@import './../../../../assets/styles/override.scss';

.for-pdf .measure-info-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  text-align: left;
}
.for-pdf .measure-info-details {
  text-align: left;
}
.abbreviation {
  font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #0a3560;
}
.measure-list-item.for-pdf {
  margin-top: 40px;
}
.measure-chart-footer {
  font-size: 12px;
  margin-bottom: 20px;

  h4 {
    font-size:12px;
    font-weight: 700;
    margin-bottom: 0;
  }
}
.for-pdf .missing-answers-div {
  text-align: center;
  margin: 32px 0;
}
.for-pdf .measure-questions-title {
  margin-top: 24px;
}
.for-pdf .measure-not-score {
  text-align: center;
  margin: 32px 0;
}
</style>
