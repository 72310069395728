import { ExecuteExpression } from '@/mixins/ExecuteExpression'
import { mapMutations } from 'vuex'

const EVALUATION_OPERATOR_SUM = '+'
const QUESTION_TYPE_TEXT = 'text'
const EVALUATION_ACTION_DISPLAY = 'display'
const EVALUATION_ACTION_HIDE = 'hide'
const QUESTION_TYPE_MODAL = 'modal'
const QUESTION_TYPE_TITLE = 'title'
const QUESTION_TYPE_PROMPT = 'prompt'

export const MeasureBranching = {
  mixins: [ExecuteExpression],
  methods: {
    ...mapMutations({
      setQuestionDefaultOpen: 'measures/SET_QUESTION_DEFAULT_OPEN'
    }),
    getQuestionsToShowForPage (page, inReport) {
      // All questions are visible by default
      let visibleQuestionsIds = page.questions.map(question => question.id)
      page.questions_branching.forEach((branchingLogic) => {
        for (let i = 0, len = branchingLogic.length; i < len; i++) {
          const branchingEvaluation = branchingLogic[i]
          if (this.getEvaluationResult(branchingEvaluation) && branchingEvaluation.affectedQuestions) {
            const affectedQuestions = branchingEvaluation.affectedQuestions.split(',').map(q => parseInt(q))
            visibleQuestionsIds = this.updateVisibleQuestionsIds(
              visibleQuestionsIds,
              branchingEvaluation.action,
              affectedQuestions
            )
            break
          }
        }
      })

      const filtered = page.questions.filter((question) => {
        question.isInformative = [QUESTION_TYPE_TEXT, QUESTION_TYPE_TITLE, QUESTION_TYPE_PROMPT, QUESTION_TYPE_MODAL].includes(question.type)
        const ignoreEmptyTextTypeText = question.type === 'text' && !question.text
        const hideInReport = inReport && question.isInformative && !question.showInReport
        if (hideInReport || (!inReport && question.type === 'prompt') || question.keepHiden || ignoreEmptyTextTypeText) {
          return false
        }

        let visible = visibleQuestionsIds.includes(question.id)
        if (visible && question.type === QUESTION_TYPE_MODAL) {
          if (this.hasOwnProperty('showQuestionModal')) { // eslint-disable-line no-prototype-builtins
            this.showQuestionModal(question)
          }
          visible = false
        }
        question.visible = visible
        return visible
      })

      if (!this.questionsLoaded) {
        this.checkAndFixPromptVisibility(filtered)
      }
      return filtered
    },
    getQuestionsToShow () {
      if (!this.singleMeasure || !this.singleMeasure.page) {
        return []
      }

      return this.getQuestionsToShowForPage(this.singleMeasure.page)
    },
    updateVisibleQuestionsIds (visibleQuestionsIdsArray, action, questionIds) {
      if (action === EVALUATION_ACTION_HIDE) {
        return this.getNewArrayWithoutValues(visibleQuestionsIdsArray, questionIds)
      }
      return this.addValuesToArray(visibleQuestionsIdsArray, questionIds)
    },
    addValuesToArray (initialArray, values) {
      values.forEach((i) => {
        if (!initialArray.includes(i)) { initialArray.push(i) }
      })
      return initialArray
    },
    getNewArrayWithoutValues (initialArray, valuesToBeRemoved) {
      return initialArray.filter((i) => !valuesToBeRemoved.includes(i))
    },
    checkAndFixPromptVisibility (questions) {
      const firstQuestionWithPrompt = questions.find(q => q.prompt)
      if (firstQuestionWithPrompt && !firstQuestionWithPrompt.prompt.defaultOpen) {
        this.setQuestionDefaultOpen({ question: firstQuestionWithPrompt.id, value: true })
      }
    },
    getQuestionVisibility (question, branchingLogic) {
      let questionVisibility = false
      for (let i = 0, len = branchingLogic.length; i < len; i++) {
        const evaluation = branchingLogic[i]
        if (this.getQuestionEvaluationResult(evaluation, question.id)) {
          questionVisibility = evaluation.action === EVALUATION_ACTION_DISPLAY
          if (questionVisibility) {
            break
          }
        } else {
          // When evaluation result is FALSE, but the action was HIDE, we need to show the question.
          questionVisibility = (evaluation.action === EVALUATION_ACTION_HIDE)
        }
      }
      if (questionVisibility && question.type === QUESTION_TYPE_MODAL) {
        this.showQuestionModal(question)
        questionVisibility = false
      }
      return questionVisibility
    },
    getEvaluationResult (evaluation) {
      if (!evaluation.conditions || evaluation.conditions.length === 0) {
        return true
      }
      let evaluationResult = true
      for (let i = 0, len = evaluation.conditions.length; i < len; i++) {
        const evalCondition = evaluation.conditions[i]
        const formulaResult = this.getQuestionBranchingFormulaResult(evalCondition.formula)
        const result = ExecuteExpression.methods.evaluateExpression(
          formulaResult,
          evalCondition.referenceValue,
          evalCondition.comparisonSymbol
        )
        if (evalCondition.concatenateValidationWith && evalCondition.concatenateValidationWith === '&&') {
          evaluationResult = evaluationResult && result
          continue
        }
        if (evalCondition.concatenateValidationWith && evalCondition.concatenateValidationWith === '||') {
          evaluationResult = evaluationResult || result
          continue
        }
        evaluationResult = result
      }
      return evaluationResult
    },
    getQuestionBranchingFormulaResult (formula) {
      let total = 0
      const formulaParts = formula.split(' ')
      if (!isNaN(formula)) {
        return formula
      }

      for (let i = 0, len = formulaParts.length; i < len; i++) {
        const item = formulaParts[i]
        const even = (i % 2 === 0)
        const value = even ? this.getQuestionValueFromReference(item) : 0
        const operator = even ? EVALUATION_OPERATOR_SUM : item
        total = ExecuteExpression.methods.calculateExpression(total, value, operator)
      }

      return total
    },
    getQuestionValueFromReference (reference) {
      if (!isNaN(reference)) {
        return reference
      }
      if (reference.indexOf('-') === -1) {
        return 0
      }
      let ref = reference
      let subIndex = false
      if (reference.includes('[')) {
        subIndex = reference.substring(
          reference.lastIndexOf('[') + 1,
          reference.lastIndexOf(']')
        )
        ref = reference.replace('[' + subIndex + ']', '')
      }
      if (reference.indexOf('-') === -1) {
        return 0
      }

      const questionReference = ref.split('-')
      const questionId = questionReference[1].replace(/[^\d.-]/g, '')
      const found = this.singleMeasure.page.questions.find((q) => {
        return q.id === Number(questionId)
      })
      if (found && subIndex) {
        return found.value && found.value.find(v => parseInt(v) === parseInt(subIndex)) ? 1 : 0
      }
      return found ? this.getValueBasedOnType(found.value) : null
    },
    getValueBasedOnType (value) {
      if (typeof value === 'object') {
        return Object.values(value).reduce((total, current) => total + parseInt(current), 0)
      }
      return value
    }
  }
}
