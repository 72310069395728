<template>
  <div
    v-if="statusIconClass && statusIconClass.text"
    class="icon"
  >
    <i
      v-if="!statusIconClass.stacked"
      :class="statusIconClass.text"
    />
    <span v-else>
      <span
        class="fa-stack status-icon"
        :class="statusIconClass.text"
      >
        <i
          class="far fa-circle fa-stack-1x"
          :class="statusIconClass.text"
        />
        <span class="fa-stack-1x icon-text">S</span>
      </span>
    </span>
  </div>
</template>

<script>
import { SESSION_STATUS_OVERDUE, SESSION_STATUS_INCOMPLETE } from '@/data/lastSessionStatus'
import { CLIENT_MEASURE_STATUS_COMPLETED } from '@/data/clientMeasureStatus'

export default {
  name: 'MeasureStatusIcon',
  props: ['session'], // eslint-disable-line vue/require-prop-types
  computed: {
    statusIconClass () {
      const r = { stacked: false, text: '' }
      if (this.session.client_measure_status && !this.session.in_progress && this.session.available) {
        r.text = 'far fa-circle available'
      } else if (this.session.in_progress && this.session.status !== SESSION_STATUS_OVERDUE && this.session.status !== SESSION_STATUS_INCOMPLETE) {
        r.text = 'fas fa-adjust partially-completed'
      } else if (this.session.status === SESSION_STATUS_OVERDUE && this.session.client_measure_status === CLIENT_MEASURE_STATUS_COMPLETED) {
        r.text = 'fas fa-check-circle completed'
      } else if (this.session.status === SESSION_STATUS_INCOMPLETE && this.session.client_measure_status === CLIENT_MEASURE_STATUS_COMPLETED) {
        r.text = 'skipped-questions'
        r.stacked = true
      } else if (this.session.client_measure_status === CLIENT_MEASURE_STATUS_COMPLETED) {
        r.text = 'fas fa-check-circle completed'
      }
      return r
    }
  }
}
</script>
<style lang="scss" scoped>
  .available{
    color: #f5a623;
  }
  .partially-completed{
    color: #f5a623;
  }
  .completed{
    color: #7ed321;
  }
  .skipped-questions{
    color: #f57723;
  }
</style>
