import {
  SS_OUTPATIENT,
  SS_PROGRAM_OPTIONS
} from '@/data/setttingStates'
import {
  loggedUserIsClinician,
  loggedUserIsStaff,
  loggedUserIsAdmin,
  getLoggedUserRoles,
  loggedUserIsSupervisor,
  loggedUserIsStaffOrClinicianOrSupervisor,
  loggedUserIsClinicianOrStaff,
  loggedUserIsOnlyStaff
} from './UserRoles'

/**
 * Determine Access to Today Tab.
 *
 * @returns {boolean}
 */
export function userHasAccessToTodayAndWeekTabs () {
  return loggedUserIsClinicianOrStaff()
}

/**
 * Determine Access To My Programs
 * @returns {boolean}
 */
export function userHasAccessToMyPrograms () {
  return loggedUserIsClinician()
}

/**
 * Determine Access To All Programs
 *
 * @returns {boolean}
 */
export function userHasAccessToAllPrograms () {
  return loggedUserIsOnlyStaff() || loggedUserIsAdmin()
}

/**
 * Determine Access To New Results
 *
 * @returns {boolean}
 */
export function userHasAccessToNewResults () {
  return loggedUserIsClinicianOrStaff()
}

/**
 * Determine Access To patients selector
 *
 * @returns {boolean}
 */
export function userHasAccessToPatientsSelector () {
  const roles = getLoggedUserRoles()
  return roles && roles.length > 1 && loggedUserIsStaffOrClinicianOrSupervisor()
}

/**
 * Determine Access To add a patient
 *
 * @returns {boolean}
 */
export function userHasAccessToAddPatient () {
  return loggedUserIsStaff()
}

/**
 * Determine Access To add a program
 *
 * @returns {boolean}
 */
export function userHasAccessToAddProgram () {
  return loggedUserIsAdmin() && SS_PROGRAM_OPTIONS.includes(this.$store.getters.generalSettings.setting_state) && this.$store.getters.generalSettings.programs_enabled
}

/**
 * Determine Access To add a clinical team
 *
 * @returns {boolean}
 */
export function userHasAccessToAddClinicalTeam () {
  return loggedUserIsAdmin() && SS_PROGRAM_OPTIONS.includes(this.$store.getters.generalSettings.setting_state) && this.$store.getters.generalSettings.programs_enabled
}

/**
 * Determine Access To add a team
 *
 * @returns {boolean}
 */
export function userHasAccessToAddTeam () {
  return loggedUserIsAdmin() && SS_PROGRAM_OPTIONS.includes(this.$store.getters.generalSettings.setting_state) && this.$store.getters.generalSettings.programs_enabled
}

/**
 * Determine Access To add a respondent
 *
 * @returns {boolean}
 */
export function userHasAccessToAddRespondent () {
  return loggedUserIsStaff()
}

/**
 * Determine Access To remove a respondent
 *
 * @returns {boolean}
 */
export function userHasAccessToRemoveRespondent () {
  return loggedUserIsStaff()
}

/**
 * Determine Access To add a measure
 *
 * @returns {boolean}
 */
export function userHasAccessToAddMeasure () {
  return loggedUserIsStaffOrClinicianOrSupervisor()
}

/**
 * Determine Access To add a patient to a program
 *
 * @returns {boolean}
 */
export function userHasAccessToAddToProgram () {
  return loggedUserIsStaffOrClinicianOrSupervisor()
}

/**
 * Determine Access To read patient / respondent information
 *
 * @returns {boolean}
 */
export function userHasAccessToReadPatientAndRespondentInformation () {
  return loggedUserIsStaffOrClinicianOrSupervisor()
}

/**
 * Determine Access To create and update patient/respondent information
 *
 * @returns {boolean}
 */
export function userHasAccessToCreateAndUpdatePatientAndRespondentInformation () {
  return loggedUserIsStaffOrClinicianOrSupervisor() || this.loggedUserIsAdmin()
}

/**
 * Determine Access To create and update patient/respondent information
 *
 * @returns {boolean}
 */
export function userHasAccessToLogInAsRespondent () {
  return loggedUserIsStaffOrClinicianOrSupervisor()
}
/**
 * Determine Access To patients slector item MyPatients
 *
 * @returns {boolean}
 */
export function userHasAccessToPatientsSelectorMyPatients () {
  return userHasAccessToPatientsSelector() && loggedUserIsClinician()
}
/**
 * Determine Access To patients slector item AllPatients
 *
 * @returns {boolean}
 */

export function userHasAccessToPatientsSelectorAllPatients () {
  return userHasAccessToPatientsSelector() && loggedUserIsStaff()
}
/**
 * Determine Access To patients slector item supervisedClinicianPatients
 *
 * @returns {boolean}
 */
export function userHasAccessToPatientsSelectorSupervisedPatients () {
  return userHasAccessToPatientsSelector() && loggedUserIsSupervisor()
}

/**
 * Determine Access To view patients list
 *
 * @returns {boolean}
 */
export function userHasAccessToReadPatientsList () {
  const settingState = this.$store.getters.generalSettings.setting_state
  return (settingState !== SS_OUTPATIENT) || (settingState === SS_OUTPATIENT && this.loggedUserIsStaffOrClinicianOrSupervisor())
}

/**
 * Determine Access To view patients list
 *
 * @returns {boolean}
 */
export function userHasAccessToAlertsList () {
  return this.loggedUserIsStaffOrClinicianOrSupervisor()
}

/**
 * Determine Access To view settings
 *
 * @returns {boolean}
 */
export function userHasAccessToSettings () {
  return this.loggedUserIsAdmin() || this.loggedUserIsStaff()
}

/**
 * Determine Access To view settings
 *
 * @returns {boolean}
 */
export function userHasAccessToSettingsClinicalUsers () {
  return this.loggedUserIsAdmin()
}

/**
 * Determine Access To view settings
 *
 * @returns {boolean}
 */
export function userHasAccessToSettingsPatientUsers () {
  return this.loggedUserIsStaff()
}

/**
 * Determine Access To view settings
 *
 * @returns {boolean}
 */
export function userHasAccessToSettingsLocations () {
  return this.loggedUserIsAdmin()
}

/**
 * Determine Access To view the homepage
 *
 * @returns {boolean}
 */
export function userHasAccessToHomePage () {
  return this.loggedUserIsClinicianOrStaff()
}
