import {
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_OTHER,
  GENDER_UNKNOWN,
  GENDER_NON_BINARY,
  GENDER_LOCALE_FEMALE,
  GENDER_LOCALE_MALE,
  GENDER_LOCALE_UNKNOWN,
  GENDER_LOCALE_OTHER,
  GENDER_LOCALE_NON_BINARY
} from '../constants/GenderValues'

/**
 * Get Person Gender Based on initial
 *
 * @param genderInitialValue
 * @returns {*}
 */
export function getPersonGenderLocaleLabel (genderInitialValue) {
  let value = null
  switch (genderInitialValue) {
    case GENDER_FEMALE:
      value = GENDER_LOCALE_FEMALE
      break
    case GENDER_MALE:
      value = GENDER_LOCALE_MALE
      break
    case GENDER_NON_BINARY:
      value = GENDER_LOCALE_NON_BINARY
      break
    case GENDER_OTHER:
      value = GENDER_LOCALE_OTHER
      break
    case GENDER_UNKNOWN:
    default:
      value = GENDER_LOCALE_UNKNOWN
      break
  }
  return value
}
