import {
  loggedUserIsAdmin,
  loggedUserIsDirector,
  loggedUserIsClinician,
  loggedUserIsStaff,
  loggedUserIsOnlyStaff,
  loggedUserIsOnlyClinician,
  loggedUserIsRespondent,
  loggedUserIsSupervisor,
  loggedUserIsClinicianOrStaff,
  loggedUserIsClinicianOrSupervisor,
  loggedUserIsStaffOrClinicianOrSupervisor,
  loggedUserIsAnyOtherThanRespondent,
  getLoggedUserHasRole,
  getLoggedUserHasAnyOfRoles,
  getLoggedUserHasPermission,
  getLoggedUserHasAnyOfPermissions
} from './UserRoles'

import {
  userHasAccessToTodayAndWeekTabs,
  userHasAccessToPatientsSelector,
  userHasAccessToMyPrograms,
  userHasAccessToAllPrograms,
  userHasAccessToAddPatient,
  userHasAccessToAddProgram,
  userHasAccessToAddClinicalTeam,
  userHasAccessToAddTeam,
  userHasAccessToSettings,
  userHasAccessToSettingsClinicalUsers,
  userHasAccessToSettingsPatientUsers,
  userHasAccessToSettingsLocations,
  userHasAccessToHomePage,
  userHasAccessToAddRespondent,
  userHasAccessToRemoveRespondent,
  userHasAccessToAddMeasure,
  userHasAccessToAddToProgram,
  userHasAccessToReadPatientAndRespondentInformation,
  userHasAccessToReadPatientsList,
  userHasAccessToPatientsSelectorMyPatients,
  userHasAccessToPatientsSelectorAllPatients,
  userHasAccessToPatientsSelectorSupervisedPatients,
  userHasAccessToLogInAsRespondent,
  userHasAccessToCreateAndUpdatePatientAndRespondentInformation,
  userHasAccessToAlertsList,
  userHasAccessToNewResults
} from './ViewElementAccess'

import {
  getNextRouteWhenGoingHome,
  userHasAccessToRoute
} from './RouteAccess'

export default {
  methods: {
    loggedUserIsAdmin,
    loggedUserIsDirector,
    loggedUserIsClinician,
    loggedUserIsStaff,
    loggedUserIsOnlyStaff,
    loggedUserIsOnlyClinician,
    loggedUserIsRespondent,
    loggedUserIsSupervisor,
    loggedUserIsClinicianOrStaff,
    loggedUserIsClinicianOrSupervisor,
    loggedUserIsStaffOrClinicianOrSupervisor,
    loggedUserIsAnyOtherThanRespondent,
    getLoggedUserHasRole,
    getLoggedUserHasAnyOfRoles,
    getLoggedUserHasPermission,
    getLoggedUserHasAnyOfPermissions,
    userHasAccessToTodayAndWeekTabs,
    userHasAccessToMyPrograms,
    userHasAccessToAllPrograms,
    userHasAccessToAddPatient,
    userHasAccessToAddProgram,
    userHasAccessToAddClinicalTeam,
    userHasAccessToAddTeam,
    userHasAccessToSettings,
    userHasAccessToSettingsClinicalUsers,
    userHasAccessToSettingsPatientUsers,
    userHasAccessToSettingsLocations,
    userHasAccessToHomePage,
    userHasAccessToAddRespondent,
    userHasAccessToRemoveRespondent,
    userHasAccessToAddMeasure,
    userHasAccessToAddToProgram,
    userHasAccessToReadPatientAndRespondentInformation,
    userHasAccessToCreateAndUpdatePatientAndRespondentInformation,
    userHasAccessToPatientsSelectorMyPatients,
    userHasAccessToPatientsSelectorAllPatients,
    userHasAccessToPatientsSelectorSupervisedPatients,
    userHasAccessToLogInAsRespondent,
    userHasAccessToPatientsSelector,
    userHasAccessToNewResults,
    getNextRouteWhenGoingHome,
    userHasAccessToReadPatientsList,
    userHasAccessToAlertsList,
    userHasAccessToRoute
  }
}
