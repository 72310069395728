<template>
  <tr
    v-if="showFirstTd || questionTextValue"
    :class="{informative: question.isInformative}"
  >
    <td
      v-if="showFirstTd"
      width="62%"
      :colspan="question.isInformative ? '2' : '1'"
    >
      <p
        class="question-text"
        v-html="question.text"
      />
      <span
        v-if="showAnswersInReport"
        class="question-answers"
      >
        <p
          v-for="(answer, index) in question.answers"
          :key="index"
        >
          {{ answer.text }}
        </p>
      </span>
    </td>
    <td
      v-if="!question.isInformative"
      :colspan="showFirstTd ? '1' : '2'"
      width="19%"
      class="question-answer"
      :class="{skipped: isSkipped}"
    >
      <span
        :style="{color: question.triggeredAlert ? 'red' : ''}"
        v-html="questionTextValue || '-'"
      />
      <i
        v-if="question.triggeredAlert"
        class="alert-bell fas fa-bell"
      />
      <span
        v-if="question.triggeredAlert"
        id="view-alert"
        @click="goToAlertTab"
      > View Alert </span>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MeasureQuestions',
  props: ['question'], // eslint-disable-line vue/require-prop-types
  computed: {
    ...mapGetters({
      generalSettings: 'generalSettings'
    }),
    showFirstTd () {
      return this.question.text || this.showAnswersInReport
    },
    showAnswersInReport () {
      return this.question.showAnswersInReport && this.question.answers && this.question.answers.length
    },
    questionTextValue () {
      let answerText = this.question.value
      if (this.question.answers) {
        if (this.question.type === 'checkbox') {
          answerText = this.getAnswerForCheckbox(this.question)
        } else {
          const answer = this.question.answers.find(a => parseInt(a.value) === parseInt(this.question.value))
          answerText = answer ? answer.text : answerText
        }
      }
      return answerText
    },
    isSkipped () {
      return this.generalSettings.unanswered_questions_feature_enabled === 1 && this.question.value === '' && !this.question.optional
    },
    currentRouteName () {
      return this.$route.name
    }
  },
  methods: {
    goToAlertTab () {
      const params = {
        ccaId: this.$route.params.ccaId,
        ccauId: this.$route.params.ccauId
      }
      this.$router.push({ name: 'ClinicianPatientAlerts', params: params })
    },
    getAnswerForCheckbox (question) {
      if (question.value) {
        const concatenatedAnswers = question.value.reduce((carry, current) => {
          const found = question.answers.find(a => parseInt(a.value) === parseInt(current))
          if (found) {
            const text = `<span class='checkbox-answer'><i class="far fa-check"></i> <span>${found.text}</span></span>`
            return carry ? `${carry}<br> ${text}` : text
          }
          return carry
        }, '')
        return concatenatedAnswers
      }
    }
  }
}
</script>
<style>
  #view-alert {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: MuseoSans_500;
    color: #2068ac;
    text-align: left;
    line-height: normal;
    font-size: small;
  }
  #view-alert:hover {
    cursor: pointer;
  }
  .alert-bell {
    color: red
  }
  .question-text {
    margin-bottom: 0;
  }
  .question-answers {
    margin-top: 5px;
    display: inline-block;
  }
  .question-answers p:last-of-type {
    margin-bottom: 0;
  }
  body.pdf .measure-questions .skipped {
    border: 1px solid #dee2e6 !important;
    background-color: #ffffff;
    color: #f57723;
  }
</style>
