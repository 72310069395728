<template>
  <div class="measure-questions">
    <div class="print-only date-and-name">
      <span class="name"> {{ assignment.full_name }} </span>
      <span class="date"> {{ customLabel(selectedSession) }} </span>
    </div>

    <!-- PDF template -->
    <div
      v-if="currentRouteName === 'ClinicianPatientMeasurePDF' || currentRouteName === 'ClinicianPatientPDF'"
      class="for-pdf"
    >
      <table class="table table-bordered">
        <thead v-if="!data.is_safety_plan">
          <tr>
            <th
              v-if="data"
              colspan="2"
              class="measure-questions-title"
            >
              <span> {{ data.abbreviation }}: {{ data.name }} </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <MeasureQuestionsSingleQuestion
            v-for="(row, index) in questions"
            :key="index"
            :question="row"
          />
        </tbody>
      </table>
    </div>

    <!-- PDF template END -->

    <div v-else>
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th class="measure-questions-title">
              <span> {{ measure.abbreviation }}: {{ measure.name }} </span>
              <MoreInfoTooltip
                v-if="moreInfo"
                :data="moreInfo"
                :reverse-icon="true"
                :close-button="false"
              />
            </th>
            <th>
              <div class="question-response-header">
                <multiselect
                  v-model="selectedSession"
                  :options="orderedCompletedSessions"
                  open-direction="bottom"
                  :searchable="false"
                  :allow-empty="false"
                  :disabled="orderedCompletedSessions.length <= 1"
                  :show-labels="false"
                  :custom-label="customLabelMulti"
                  @select="changeSession"
                />
                <span
                  v-if="generalSettings.unanswered_questions_feature_enabled === 1"
                  class="reference"
                >
                  <span class="square skipped" />
                  <span class="text">Skipped</span>
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <MeasureQuestionsSingleQuestion
            v-for="(row, index) in questions"
            :key="index"
            :question="row"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<style>

</style>
<script>
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import { MeasureBranching } from '@/mixins/MeasureBranching'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import MoreInfoTooltip from '@/components/common/MoreInfoTooltip'
import MeasureQuestionsSingleQuestion from '@/components/clinician/patients/measures/MeasureQuestionsSingleQuestion'
import Multiselect from 'vue-multiselect'
import moment from 'moment'

const QUESTION_TYPE_TEXT = 'text'
const QUESTION_TYPE_TITLE = 'title'
const QUESTION_TYPE_PROMPT = 'prompt'
const QUESTION_TYPE_MODAL = 'modal'

export default {
  name: 'MeasureQuestions',
  components: {
    Multiselect,
    MeasureQuestionsSingleQuestion,
    MoreInfoTooltip
  },
  mixins: [Helpers, MeasureBranching, DateTimeHelper],
  props: ['audition', 'moreInfo', 'data', 'questionArray'],
  data () {
    return {
      measureId: '',
      loading: false,
      selectedSession: {},
      questions: [],
      singleMeasure: {},
      questionFormulaCurrentResult: [],
      questionFormulaLastResult: []
    }
  },
  computed: {
    ...mapGetters({
      teamId: 'getTeamId',
      assignment: 'getActiveAssignment',
      generalSettings: 'generalSettings',
      details: 'measureLibraryDetail',
      completedSessions: 'getPatientSingleMeasureCompletedSessions',
      measure: 'getMeasureQuestions',
      auditionData: 'getAuditionData'
    }),
    getReportAction () {
      return this.audition ? 'GET_AUDITION_ANSWERS_REPORT' : 'GET_MEASURE_QUESTIONS'
    },
    orderedCompletedSessions () {
      return Object.values(this.completedSessions).sort((a, b) => b.completion_date.localeCompare(a.completion_date))
    },
    currentRouteName () {
      return this.$route.name
    }
  },
  watch: {
    '$route.params.measureId' () {
      this.getData()
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.client_measure_id = this.audition ? this.details.measure_id : this.$getDecodedId(this.$route.params.measureId)
      this.selectedSession = this.completedSessions && this.completedSessions.length ? this.completedSessions.find(s => parseInt(s.id) === parseInt(this.client_measure_id)) : ''
      this.fetchMeasure()
    },
    customLabel (session) {
      const format = 'MMM DD, YYYY'
      const date = session && session.completion_date ? this.$toLocal(session.completion_date, format) : moment().format(format)
      return date.toUpperCase()
    },
    customLabelMulti (options) {
      const datetime = options.completion_date
      const dateFormat = 'MMM DD, YYYY'
      const timeFormat = 'h:mm A'
      const date = this.$toLocal(datetime, dateFormat)
      const time = this.$toLocal(datetime, timeFormat)
      return `${date} ${time}`
    },
    changeSession (session) {
      const params = {
        ccaId: this.$route.params.ccaId,
        ccauId: this.$route.params.ccauId,
        measureId: this.$getEncodedId(session.id)
      }
      this.$router.push({ name: 'SessionDetail', params: params })
    },
    getQuestionsToShowCustom () {
      if (this.currentRouteName === 'ClinicianPatientPDF' && this.questionArray) {
        const pages = this.questionArray.questionnaire_pages ? this.questionArray.questionnaire_pages : []
        return pages.reduce((response, currentPage) => {
          this.questionArray.page = currentPage
          this.singleMeasure.page = currentPage
          const questionsToShow = this.getQuestionsToShowForPage(currentPage, true).filter(this.isQuestionVisible)

          // Page Titles goes at the top of the page not matter what.
          if (currentPage.showTitleInReport && currentPage.title) {
            questionsToShow.unshift({ text: currentPage.title, isInformative: true })
          }

          // Page Prompts should be shown just Before the First question that can show them (based on hidePrompt)
          if (currentPage.showPromptInReport && currentPage.prompt) {
            const index = questionsToShow.findIndex((element) => {
              return !element.hasOwnProperty('hidePrompt') || !element.hidePrompt // eslint-disable-line no-prototype-builtins
            })
            questionsToShow.splice(index, 0, { text: currentPage.prompt, isInformative: true })
          }

          return [...response, ...questionsToShow]
        }, [])
      } else {
        const pages = this.measure.questionnaire_pages ? this.measure.questionnaire_pages : []
        return pages.reduce((response, currentPage) => {
          this.singleMeasure.page = currentPage
          const questionsToShow = this.getQuestionsToShowForPage(currentPage, true).filter(this.isQuestionVisible)

          // Page Titles goes at the top of the page not matter what.
          if (currentPage.showTitleInReport && currentPage.title) {
            questionsToShow.unshift({ text: currentPage.title, isInformative: true })
          }

          // Page Prompts should be shown just Before the First question that can show them (based on hidePrompt)
          if (currentPage.showPromptInReport && currentPage.prompt) {
            const index = questionsToShow.findIndex((element) => {
              return !element.hasOwnProperty('hidePrompt') || !element.hidePrompt // eslint-disable-line no-prototype-builtins
            })
            questionsToShow.splice(index, 0, { text: currentPage.prompt, isInformative: true })
          }

          return [...response, ...questionsToShow]
        }, [])
      }
    },
    isQuestionVisible (question) {
      const ignoreEmptyTextTypeText = question.type === 'text' && !question.text
      question.isInformative = [QUESTION_TYPE_TEXT, QUESTION_TYPE_TITLE, QUESTION_TYPE_PROMPT, QUESTION_TYPE_MODAL].includes(question.type)
      if (question.keepHiden || ignoreEmptyTextTypeText || (question.isInformative && !question.showInReport)) {
        return false
      }
      return true
    },
    fetchMeasure () {
      // eslint-disable-next-line no-unused-vars
      const params = ''
      if (this.currentRouteName === 'ClinicianPatientMeasurePDF') {
        this.params = this.data.id
        this.$store.dispatch(this.getReportAction, this.params).then(() => {
          this.questions = this.getQuestionsToShowCustom()
          if (this.data.abbreviation === 'SB Safety Plan') {
            this.questions.pop()
          }
        }).catch(() => {
          this.$router.push({ name: 'PageNotFound' })
        })
      } else if (this.currentRouteName === 'ClinicianPatientPDF') {
        this.questions = this.getQuestionsToShowCustom()
      } else {
        const loader = this.$loading.show()
        this.loading = true
        this.params = this.audition ? { measureId: this.details.measure_id, measureData: this.auditionData } : this.client_measure_id
        this.$store.dispatch(this.getReportAction, this.params).then(() => {
          this.questions = this.getQuestionsToShowCustom()
        }).catch(() => {
          this.$router.push({ name: 'PageNotFound' })
        }).finally(() => {
          this.loading = false
          loader.hide()
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.for-pdf .table {
  background: #fff;
}
.for-pdf .table thead th {
    border-bottom: none;
}
.measure-questions .for-pdf .table {
    background: #fff;
    border-collapse: collapse;
    border-spacing: 0;
}
h2 {
      font-size: 16px !important;
  }
.measure-questions .table th:nth-child(2) {
  min-width: 290px;
}
</style>
