import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import { INACTIVE_USER_STRING } from '@/store/modules/user/constants.js'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'

const SCHEDULE_BEHAVIOR = 1
const PROGRAM_BEHAVIOR = 2

export const PatientHelper = {
  mixins: [Helpers, UserManagementComponentMixin],
  data () {
    return {
      logingIn: false
    }
  },
  methods: {
    $getPatientFormBehavior (appointmentsEnabled, programsEnabled, programId) {
      // When Both settings enabled, and a preselected program id is present, behave as program.
      if (appointmentsEnabled && programsEnabled && programId) {
        return PROGRAM_BEHAVIOR
      }

      // when both settings enabled and a preselected program is missing, behave as schedule
      if (appointmentsEnabled && programsEnabled && !programId) {
        return SCHEDULE_BEHAVIOR
      }

      // behave as program if enabled otherwise as schedule.
      return programsEnabled ? PROGRAM_BEHAVIOR : SCHEDULE_BEHAVIOR
    },

    $getPatientFormScheduleBehaviorId () {
      return SCHEDULE_BEHAVIOR
    },

    $getPatientFormProgramBehaviorId () {
      return PROGRAM_BEHAVIOR
    },
    $reactivateIfInactive (assignment) {
      return new Promise((resolve) => {
        const status = assignment.patient_status ? assignment.patient_status : assignment.status
        if (status && status.toLowerCase() === INACTIVE_USER_STRING) {
          const patient = {
            status: 'active',
            isAutoActivation: true,
            ccauId: assignment.ccauId,
            id: assignment.patient_status ? assignment.patient_id : assignment.user_id
          }
          // Re activate and continue
          this.$store.dispatch('UPDATE_PATIENT', patient).then(() => {
            this.$updateAssignments(assignment.assignment_id, assignment.ccauId).then(() => {
              resolve()
            })
          })
        } else {
          // we can continue
          resolve()
        }
      })
    },
    $updateAssignments (ccaId, ccauId) {
      return this.$store.dispatch('GET_ASSIGNMENTS', ccaId).then(() => {
        this.$store.dispatch('SET_ASSIGNMENT_VIEW', ccauId)
      }).catch(() => {})
    },
    promptBeforeClose () {
      const routeName = this.$route.name
      return new Promise((resolve) => {
        const allowedRoutes = ['ClinicianAddPatientToProgram', 'ClinicianAddPatient', 'ClinicianEditPatient', 'ClinicianAddMeasureToPatient', 'ClinicianAddProgramToPatient', 'ClinicianAddPatientByProgram', 'ClinicianPatientsByProgramAddMeasureToPatient']
        // Show prompt if in adding respondent to patient flow
        if (this.addRespondentToPatient) {
          allowedRoutes.push('PatientsByProgram')
        }

        if (allowedRoutes.includes(routeName)) {
          let message = ''
          switch (routeName) {
            case 'ClinicianAddMeasureToPatient':
            case 'ClinicianPatientsByProgramAddMeasureToPatient':
              message = this.$t('measureWontBeSaved')
              break
            case 'ClinicianAddPatientToProgram':
              message = this.$t('patientWontBeAddedToProgam')
              break
            case 'ClinicianAddProgramToPatient':
              message = this.$t('patientWontBeAddedToProgam')
              break
            case 'PatientsByProgram':
              message = this.$t('respondentWontBeSaved')
              break
            default:
              message = this.$t('patientWontBeSaved')
          }
          const promptOptions = {
            message: message,
            title: this.$t('areYouSureToExit'),
            okButton: this.$t('yesExit')
          }
          this.$promptBeforeAction(promptOptions, () => {
            resolve()
          })
        } else {
          resolve()
        }
      })
    },
    loginAs (id) {
      if (this.logingIn || !this.userHasAccessToLogInAsRespondent) {
        return
      }
      this.logingIn = true
      this.$store.dispatch('LOGIN_AS_RESPONDENT', id).then(() => {
        return this.$router.push({
          name: 'Session',
          params: {}
        })
      }).catch((e) => {
        this.$handleApiError(e)
      }).finally(() => {
        this.logingIn = false
      })
    },
    getPatientId () {
      if (this.$route.name === 'ClinicianHomePageAddMeasure') {
        const ccauId = this.$getDecodedId(this.$route.params.ccauId)
        const ccaId = this.$getDecodedId(this.$route.params.ccaId)
        const assignment = this.assignments.find(a => parseInt(a.assignment_id) === parseInt(ccaId) && parseInt(a.ccauId) === parseInt(ccauId))

        return assignment ? assignment.user_id : null
      }
      return (this.$route.params.patientId) ? this.$getDecodedId(this.$route.params.patientId) : this.activeAssignment.user_id
    },
    getPatient (patientId) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('GET_PATIENT', patientId).then(() => {
          resolve()
        }).catch((e) => {
          reject(e)
          this.$handleApiError(e)
        })
      })
    },
    dischargeIfPreDischarged (message) {
      if (this.showProgramsUserIsMember) {
        const programAssignation = this.activePatientProgramsAssignation[0]
        if (parseInt(programAssignation.pre_discharged)) {
          const params = {
            access_control_program_id: programAssignation.id,
            patient_id: programAssignation.access_control_id,
            assignment_id: this.assignment.assignment_id,
            date: programAssignation.discharge_date,
            disposition_status: programAssignation.disposition_status
          }
          this.$store.dispatch('DISCHARGE_FROM_PROGRAM', params).then((responseData) => {
            const discharged = responseData.result
            if (discharged) {
              this.$toast.success({ message: message })
            }
          }).catch(e => this.$handleApiError(e))
        }
      }
    },
    getRespondentLatestSession () {
      return new Promise((resolve) => {
        const ccauId = this.$getDecodedId(this.$route.params.ccauId)
        if (!ccauId) {
          resolve()
          return false
        }

        const params = { ccauId }

        if (this.activePatientProgramsAssignation.length) {
          params.programId = this.activePatientProgramsAssignation[0].program_id
        }

        this.$store.dispatch('GET_RESPONDENT_LATEST_SESSION', params).catch((e) => {
          this.$handleApiError(e)
        }).finally(() => {
          resolve()
        })
      })
    },
    $refreshActiveAssignmentData () {
      if (this.activeAssignment && this.activeAssignment.assignment_id) {
        return this.$store.dispatch('GET_ASSIGNMENTS', this.activeAssignment.assignment_id).then(() => (
          this.$store.commit('patient/SET_ASSIGNMENT_VIEW', this.activeAssignment.ccauId)
        )).catch(() => {})
      }
    },
    $updatePatientOverview () {
      this.$refreshActiveAssignmentData()
      this.getRespondentLatestSession()
    },
    $refreshCurrentOverviewSection () {
      componentRefreshSingleton.refreshComponents()
    }
  },
  computed: {
    ...mapGetters({
      patientProgramsAssignation: 'getSinglePatientPrograms',
      activeAssignment: 'getActiveAssignment',
      settings: 'generalSettings'
    }),
    activePatientProgramsAssignation () {
      return this.patientProgramsAssignation.filter((pa) => Number(pa.discharged) !== 1)
    },
    showProgramsUserIsMember () {
      const programs = this.activePatientProgramsAssignation.reduce((programIds, pa) => {
        return programIds.includes(pa.program.id) ? programIds : [...programIds, pa.program.id]
      }, [])
      return this.settings.programs_enabled && programs.length === 1
    },
    patientInformationReadOnly () {
      return !this.userHasAccessToCreateAndUpdatePatientAndRespondentInformation()
    }
  }
}
