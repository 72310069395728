<template>
  <!-- Session Detail template -->
  <div
    v-if="currentRouteName === 'ClinicianPatientMeasurePDF' && !measureLoading && !measuresLoading && !assignmentsLoading"
    class="pdf-main"
  >
    <div class="pdf-header flex flex-col">
      <!-- Safety Plan -->
      <div v-if="measure.is_safety_plan">
        <div class="pdf-header-patient">
          <div class="sp-header-title">
            <span>{{ fullNamePatient }}</span>
            <span>{{ sessionDate }}</span>
          </div>
        </div>
        <measure-questions
          ref="measureQuestionsElement"
          :more-info="measure.more_info"
          :data="measure"
          :audition="false"
        />
      </div>
      <!-- Safety Plan END -->
      <div v-if="!measure.is_safety_plan">
        <div
          class="pdf-header-patient"
        >
          <span class="header-title">
            {{ fullNamePatient }} {{ sessionDate }}
          </span> {{ age }} {{ gender }} {{ mrn }}
        </div>
        <div
          class="pdf-header-measures"
        >
          {{ fullNameRespondent }} {{ patientRelation }} {{ measure.abbreviation }}
        </div>
        <div>
          <Measure-list-item
            :data="measure"
            :chart-index-init="getChartIndex(measure)"
            :audition="false"
            :missing-answers="measure.sessions_completed.length ? measure.missing_answers : null"
            is-progress-chart="true"
          />
        </div>
        <div>
          <SessionDetailPDF
            :data="measure"
            :charts="measure.chart"
            :assignment="activeAssignment"
            :single-measure-completed-sessions="singleMeasureCompletedSessions"
            :measure-loading="measureLoading"
            :missing-answers="measure.sessions_completed.length ? measure.missing_answers : null"
            session="true"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Session All (Redox) template -->
  <div
    v-else-if="currentRouteName === 'ClinicianPatientPDF' && !measuresLoading && !assignmentsLoading && !questionsListLoading"
    class="pdf-main"
  >
    <div class="pdf-header flex flex-col">
      <div class="pdf-header-patient">
        <span
          v-if="measures"
          class="header-title"
        >
          {{ fullNamePatient }} {{ sessionDate }}
        </span> {{ age }} {{ gender }} {{ mrn }}
      </div>
      <div
        v-if="activeAssignment"
        class="pdf-header-measures"
      >
        {{ fullNameRespondent }} {{ patientRelation }} ( {{ abbreviationsToString }} )
      </div>
    </div>
    <div>
      <Measure-list-item
        v-for="(measure, index) in measures"
        :key="measure.id"
        :data="measure"
        :chart-index-init="getChartIndex(measure)"
        :index="index"
        :audition="false"
        :missing-answers="measure.sessions_completed.length ? measure.missing_answers : null"
        is-progress-chart="true"
      />
    </div>
    <div v-if="questionsList.length && !questionsListLoading">
      <SessionDetailPDF
        v-for="(measure, index) in measures"
        :key="measure.id"
        :index="index"
        :data="measure"
        :question-array="getQuestionsList(measure.id)"
        :charts="measure.chart"
        :measure-index="index"
        :missing-answers="measure.sessions_completed.length ? measure.missing_answers : null"
        :assignment="activeAssignment"
        session="false"
      />
    </div>
  </div>
</template>

<script>
import SessionDetailPDF from '@/components/clinician/patients/measures/SessionDetailPDF'
import { LastSessionDetail } from '@/mixins/LastSessionDetail'
import MeasureListItem from './measures/MeasureListItem'
import MeasureQuestions from '@/components/clinician/patients/measures/MeasureQuestions'
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import { PatientHelper } from '@/mixins/PatientHelper'
import moment from 'moment'
import { getPersonGenderLocaleLabel } from '@/mixins/persons/PersonGender'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'

export default {
  name: 'ClinicianPatientPDF',
  components: {
    SessionDetailPDF,
    MeasureListItem,
    MeasureQuestions
  },
  mixins: [Helpers, LastSessionDetail, PatientHelper, DateTimeHelper],
  data: () => {
    return {
      cca_id: null,
      ccauId: null,
      patientId: null,
      respondentId: null,
      singleProgressIndex: null,
      measureId: null,
      chartIndexInit: null,
      sessionAbbreviations: [],
      measuresNoBundles: [],
      ready: false,
      questionIds: {
        clientMeasureIds: []
      }
    }
  },
  computed: {
    ...mapGetters({
      measure: 'getPatientSingleMeasurePDF',
      measureLoading: 'getPatientSingleMeasurePDFLoading',
      singleMeasureCompletedSessions: 'getPatientSingleMeasureCompletedSessions',
      measures: 'getPatientMeasuresPDF',
      measuresLoading: 'getPatientMeasuresPDFLoading',
      measureIds: 'getPDFMeasureIds',
      measureInfo: 'getPatientMeasuresPDFData',
      questionsList: 'getMultiMeasureQuestions',
      questionsListLoading: 'getMultiMeasureQuestionsLoading',
      patientData: 'getSinglePatient',
      activeAssignment: 'getActiveAssignment',
      assignments: 'getAssignments',
      assignmentsLoading: 'getAssignmentsLoading',
      latestSession: 'getRespondentLatestSession',
      latestSessionLoading: 'getRespondentLatestSessionLoading'
    }),
    programsEnabled () {
      return this.$store.getters.generalSettings.programs_enabled
    },
    isPatient () {
      return this.activeAssignment.patient_relation_id === 1
    },
    currentRouteName () {
      return this.$route.name
    },
    fullNamePatient () {
      // activeAssignment.full_name is string with 3 spaces if Patient name is left blank at generation
      return (this.assignmentsLoading === false && Object.keys(this.assignments[0]).length > 1 && this.assignments[0].patient_name.trim()) ? this.assignments[0].patient_name + ':' : ''
    },
    fullNameRespondent () {
      return (this.assignmentsLoading === false && Object.keys(this.activeAssignment).length > 1 && this.activeAssignment.full_name.trim()) ? this.activeAssignment.full_name + ',' : ''
    },
    mrn () {
      return (this.assignmentsLoading === false && this.assignments[0].mrn) ? '#' + this.assignments[0].mrn : ''
    },
    age () {
      return (this.assignmentsLoading === false && this.assignments[0].birth_date) ? moment().diff(this.assignments[0].birth_date, 'years') + 'y' : ''
    },
    gender () {
      return (this.assignmentsLoading === false && this.assignments[0].gender) ? this.$t(getPersonGenderLocaleLabel(this.assignments[0].gender)) + ',' : ''
    },
    patientRelation () {
      return (this.activeAssignment.patient_relation) ? this.activeAssignment.patient_relation + ':' : ''
    },
    abbreviationsToString () {
      return this.measureInfo.sessionAbbreviations.join(', ')
    },
    sessionDate () {
      return this.getSessionDate()
    },
    isReady () {
      return !this.measureLoading &&
        !this.measuresLoading &&
        !this.questionsListLoading &&
        !this.assignmentsLoading &&
        !this.getRespondentLatestSessionLoading
    }
  },
  watch: {
    isReady: {
      handler () {
        this.showReady()
      }
    }
  },
  async created () {
    this.cca_id = this.$getDecodedId(this.$route.params.ccaId)
    this.ccauId = parseInt(this.$getDecodedId(this.$route.params.ccauId))
    if (this.currentRouteName === 'ClinicianPatientMeasurePDF') {
      this.measureId = this.$getDecodedId(this.$route.params.measureId)
    }
    this.getRespondentLatestSession()
    if (this.currentRouteName === 'ClinicianPatientMeasurePDF') {
      this.getSingleMeasure()
      await this.$nextTick()
    }
    this.getMeasures()
    this.getAssignments()
    document.body.classList.add('pdf')
  },
  async updated () {
    await this.$nextTick()
    this.getSessionDate()
    if (!this.assignmentsLoading) {
      document.title = this.fullNamePatient + ' ' + this.sessionDate + ' ' + this.age + ' ' + this.gender + ' ' + this.mrn
    }
    await this.$nextTick()
    this.showReady()
  },
  methods: {
    async showReady () {
      if (this.isReady === true && this.ready === false) {
        this.ready = true
        const buffer = this.currentRouteName === 'ClinicianPatientPDF' ? 2000 : 1000
        await this.$asyncTimeout(buffer)
        // eslint-disable-next-line no-console
        console.log('ready =', this.isReady)
      }
    },
    getQuestionsList (measureId) {
      return this.questionsList.find(item => Number(item.client_measure_id) === Number(measureId))
    },
    getSingleMeasure () {
      const params = {
        measureId: this.measureId
      }
      this.$store.dispatch('GET_PATIENT_SINGLE_MEASURE_PDF', params)
    },
    getMeasures () {
      this.$store.dispatch('GET_PATIENTS_MEASURES_PDF', this.ccauId)
    },
    async getAssignments () {
      await this.$store.dispatch('GET_ASSIGNMENTS', this.cca_id)
      await this.setActiveAssignment()
    },
    getChartIndex (measure) {
      if (this.$route.query.chartIndex && this.$route.query.measureId && measure.id === this.$route.query.measureId) {
        return this.$route.query.chartIndex
      }
      return 0
    },
    setActiveAssignment () {
      this.$store.dispatch('SET_ASSIGNMENT_VIEW', this.ccauId)
    },
    getSessionDate () {
      return this.currentRouteName === 'ClinicianPatientMeasurePDF' ? this.getSingleSessionDate() : this.getLatestSessionDate()
    },
    getSingleSessionDate () {
      const singleSession = this.measure.sessions_completed
      const singleSessionCompleted = singleSession.find((item) => item.id === Number(this.measureId))
      return !this.singleMeasureLoading ? this.$toLocal(singleSessionCompleted.measure_date_completed, 'MMM DD, YYYY') : null
    },
    getLatestSessionDate () {
      return !this.measuresLoading && !this.latestSessionLoading ? this.$toLocal(this.latestSession.complete.completed_date, 'MMM DD, YYYY') : null
    }
  }
}
</script>
<style lang="scss">
  body.pdf div iframe {
    display: none;
  }
  body.pdf .measure-list {
    margin-top: 40px;
  }
  .pdf-header {
    margin-bottom: 40px;
    text-align: left;
      .pdf-header-patient {
        padding: 6px 0;
        .header-title {
          font-size: 18px;
          font-weight: 700;
        }
      }
      .pdf-header-measures {
        background: #f1f5f8;
        border:  1px solid #b3c1d1;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 14px;
      }
  }
  .sp-header-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 22px;
  }
  .measure-questions .for-pdf .table thead {
    background-color: #f1f5f8;
  }
  .measure-questions-title {
    font-size: 16px;
  }
  .measure-info-abbr.abbreviation {
    font-size: 18px;
  }
  .headerLeft {
    position: running(headerLeft);
    font-size:11pt;
  }
  .headerRight {
    position: running(headerRight);
    font-size: 11pt;
    font-style: italic;
    text-align: right;
    color:#667eea;
  }
  svg {
    width: 100%;
  }
  .measure-questions .for-pdf .table td {
    text-align: left;
    font-weight: 300;
    font-size: 12pt;
    padding: 16px;
    color: #0a3560;
    line-height: 18px;
  }
  .charts-with-score {
    display: block !important;
  }
  #app {
    height: 100%;
    text-align: left;
  }
  @media print {
    .pdf-main {
      margin: 20mm;
    }
    @page {
      size: A4 portrait;
        @bottom-right {
          content: counter(page) ' / ' counter(pages);
          border-top: 1px solid #b3c1d1;
        }
        @bottom-center {
          border-top: 1px solid #b3c1d1;
        }
        @bottom-left {
          content: '©2021 Owl Outcomes';
          border-top: 1px solid #b3c1d1;
        }
      }
  }
</style>
