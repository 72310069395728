export const ExecuteExpression = {
  methods: {
    evaluateExpression: function (v1, v2, comparisonType) {
      let valid = false
      switch (comparisonType) {
        case '>':
        case 'greaterThan':
          valid = parseInt(v1) > parseInt(v2)
          break
        case '>=':
        case 'greaterOrEqualThan':
          valid = parseInt(v1) >= parseInt(v2)
          break
        case '<':
        case 'lessThan':
          valid = parseInt(v1) < parseInt(v2)
          break
        case '<=':
        case 'lessOrEqualThan':
          valid = parseInt(v1) <= parseInt(v2)
          break
        case '==':
          valid = parseInt(v1) == parseInt(v2) // eslint-disable-line eqeqeq
          break
        case '===':
        case 'equal':
          valid = parseInt(v1) === parseInt(v2)
          break
        case '!=':
          valid = parseInt(v1) != parseInt(v2) // eslint-disable-line eqeqeq
          break
        case '!==':
        case 'notEqual':
          valid = parseInt(v1) !== parseInt(v2)
          break
      }

      return valid
    },
    calculateExpression: function (v1, v2, operator) {
      let result = null
      switch (operator) {
        case '+':
          result = Number(v1) + Number(v2)
          break
        case '-':
          result = Number(v1) - Number(v2)
          break
        case '*':
          result = Number(v1) * Number(v2)
          break
        case '/':
          result = Number(v1) / Number(v2)
          break
        case '^':
          result = Number(v1) ^ Number(v2)
          break
        case '%':
          result = Number(v1) % Number(v2)
          break
      }
      return result
    }
  }
}
