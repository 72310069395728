import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import { PatientHelper } from '@/mixins/PatientHelper'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'

export const LastSessionDetail = {
  mixins: [PatientHelper, DateTimeHelper, UserManagementComponentMixin],
  computed: {
    ...mapGetters({
      lastSessionDetail: 'getLastSessionDetail',
      teamDetail: 'getTeamLatestSession',
      detailViewOpened: 'getDetailVewOpened',
      patientsByMyPrograms: 'getMyPrograms',
      homeTab: 'getHomeTab',
      patientsByAllPrograms: 'getAllPrograms',
      patientsByProgram: 'getPatientsByProgram'
    }),
    showAddRespondent () {
      return this.userHasAccessToAddRespondent()
    }
  },
  methods: {
    ...mapMutations({
      setLastSessionDetail: 'lastSession/SET_LAST_SESSION_DETAIL',
      setDetailViewOpened: 'user/SET_DETAIL_VIEW_OPENED'
    }),
    openLastSessionDetail (patient, onlyWhenOpenedAlready) {
      if (onlyWhenOpenedAlready && !this.detailViewOpened) {
        return false
      }

      const params = {
        id: patient.access_control_id || patient.id,
        client_clinic_assignment_user_id: patient.client_clinic_assignment_user_id,
        name: patient.name || patient.full_name,
        initials: patient.initials,
        first_name: patient.first_name,
        last_name: patient.last_name,
        ccaId: patient.assignment_id || patient.client_clinic_assignment_id || patient.ccaId,
        programId: patient.program_id || patient.programId
      }

      this.setDetailViewOpened(params.id)
      this.fetchTeamLastSession(params)
    },
    openPatientLastSessionDetail () {
      const params = {
        id: this.activeAssignment.user_id,
        client_clinic_assignment_user_id: this.activeAssignment.ccauId,
        initials: `${this.activeAssignment.first_name[0]}${this.activeAssignment.last_name[0]}`,
        first_name: this.activeAssignment.first_name,
        last_name: this.activeAssignment.last_name,
        name: this.activeAssignment.full_name,
        ccaId: this.activeAssignment.assignment_id
      }

      if (this.activePatientProgramsAssignation.length && this.activePatientProgramsAssignation[0].program_id) {
        params.programId = this.activePatientProgramsAssignation[0].program_id
      }

      this.fetchTeamLastSession(params)
    },
    validateOpenedDetailIsStillAvailable (programId) {
      if (this.detailViewOpened) {
        if (!programId) {
          return
        }
        const assignations = this.patientsByProgram
        const openedProgram = assignations.patients.find(p => parseInt(p.access_control_id) === parseInt(this.detailViewOpened))
        if (!openedProgram) { // if not available then close the detail view
          this.setDetailViewOpened(false)
        }
      }
    },
    fetchTeamLastSession (params, onlyWhenOpen) {
      if (onlyWhenOpen && !this.detailViewOpened) {
        return false
      }
      const loader = this.$loading.show()
      return this.$store.dispatch('GET_TEAM_LATEST_SESSION', params).then((teamDetail) => {
        params.teamDetail = teamDetail
        if (this.$route.name === 'ClinicianHomepage') {
          this.validateOpenedDetailIsStillAvailable(params.programId)
        }
        this.setLastSessionDetail(params)
      }).catch((e) => {
        this.$handleApiError(e)
      }).finally(() => {
        loader.hide()
      })
    },
    closeLastSessionDetail () {
      this.$store.dispatch('RESET_LAST_SESSION')
      this.setDetailViewOpened(false)
    },
    openMeasureSessionDetail (rowData) {
      const params = {
        assignmentId: rowData.assignment_id,
        sessionDueDate: moment(rowData.selectedDate).format('YYYY-MM-DD')
      }
      this.setDetailViewOpened(params.access_control_id)
      return this.$store.dispatch('GET_TEAM_MEASURE_SESSION', params).then(() => {
        this.setDetailViewOpened(true)
        this.setTeamSessionDetailsFromRow(rowData)
      }).catch((e) => {
        this.$handleApiError(e)
      })
    },
    setTeamSessionDetailsFromRow (rowData) {
      const params = {
        id: rowData.access_control_id || rowData.id,
        client_clinic_assignment_user_id: rowData.client_clinic_assignment_user_id,
        name: rowData.name || rowData.full_name,
        initials: rowData.initials,
        first_name: rowData.first_name,
        last_name: rowData.last_name,
        ccaId: rowData.assignment_id,
        teamDetail: this.teamDetail,
        programId: null
      }
      this.setLastSessionDetail(params)
    }
  }
}
